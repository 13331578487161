// extracted by mini-css-extract-plugin
export var base00 = "colours-module--base00--kQhiO";
export var base01 = "colours-module--base01--sErEP";
export var base02 = "colours-module--base02--tomaq";
export var base03 = "colours-module--base03--Wdzu-";
export var base04 = "colours-module--base04--WrMix";
export var base05 = "colours-module--base05--d0Wcr";
export var base06 = "colours-module--base06--qSh6V";
export var base07 = "colours-module--base07--WLFJ+";
export var base08 = "colours-module--base08--Vtf4q";
export var base09 = "colours-module--base09--eJyhu";
export var base0a = "colours-module--base0a--6qucW";
export var base0b = "colours-module--base0b--W6fKY";
export var base0c = "colours-module--base0c--BMf0L";
export var base0d = "colours-module--base0d--IBTVK";
export var base0e = "colours-module--base0e--kuMdD";
export var base0f = "colours-module--base0f--GrIIj";
export var heroLogo = "colours-module--heroLogo--O3rUh";
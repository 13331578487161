// extracted by mini-css-extract-plugin
export var base00 = "card-module--base00--w+1EF";
export var base01 = "card-module--base01--oU3YD";
export var base02 = "card-module--base02--l1DEk";
export var base03 = "card-module--base03--2EFkr";
export var base04 = "card-module--base04--zZUwh";
export var base05 = "card-module--base05--I4o8h";
export var base06 = "card-module--base06--R547q";
export var base07 = "card-module--base07--UQz4u";
export var base08 = "card-module--base08--u0dvQ";
export var base09 = "card-module--base09--l-BSn";
export var base0a = "card-module--base0a--p-iRy";
export var base0b = "card-module--base0b--XjRXc";
export var base0c = "card-module--base0c--lYu6d";
export var base0d = "card-module--base0d--G9n7I";
export var base0e = "card-module--base0e--1QUkr";
export var base0f = "card-module--base0f--WI5fR";
export var body = "card-module--body--fh3o3";
export var deck = "card-module--deck--0LJYz";
export var headline = "card-module--headline--s5C4H";
export var heroLogo = "card-module--heroLogo--oauRr";
export var key = "card-module--key--bpN18";
export var listTitle = "card-module--listTitle--Drcxt";
export var meta = "card-module--meta--NidXn";
export var metadata = "card-module--metadata--2ZuxK";
export var single = "card-module--single--LII9S";
export var summary = "card-module--summary--bLeR6";
export var val = "card-module--val--uBvMu";
// extracted by mini-css-extract-plugin
export var base00 = "deck-module--base00--aTMm4";
export var base01 = "deck-module--base01--fGxxN";
export var base02 = "deck-module--base02--yqsTD";
export var base03 = "deck-module--base03--4OmKL";
export var base04 = "deck-module--base04--zy6C5";
export var base05 = "deck-module--base05--PpIs7";
export var base06 = "deck-module--base06--Y5ZdG";
export var base07 = "deck-module--base07--D3PiX";
export var base08 = "deck-module--base08--pTNPj";
export var base09 = "deck-module--base09--ZjcxX";
export var base0a = "deck-module--base0a--rffHc";
export var base0b = "deck-module--base0b--LoYPt";
export var base0c = "deck-module--base0c--nFFb4";
export var base0d = "deck-module--base0d--hzybu";
export var base0e = "deck-module--base0e--t9m+7";
export var base0f = "deck-module--base0f--W2i6t";
export var deck = "deck-module--deck--gfIQ7";
export var heroLogo = "deck-module--heroLogo--dugXN";
export var listTitle = "deck-module--listTitle--lvXtH";